import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started-"
    }}>{`Getting Started 🚀`}</h1>
    <p>{`This guide will take you through setting up your first Workflow and inviting your first user to demonstrate how quick we can get up and running 🏃`}</p>
    <p>{`There's a few steps in the process:`}</p>
    <ol>
      <li parentName="ol">{`Create a Workflow ⚡️`}</li>
      <li parentName="ol">{`Create a Position 🧑‍🍳`}</li>
      <li parentName="ol">{`Invite an Employee 👨🏼`}</li>
    </ol>

    <SupportFooter linkTo="/support/getting-started/create-a-workflow" linkText="Let's get started" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      